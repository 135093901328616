import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos';

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"


const RecursosPDVPage = () => {

  useEffect(() => {
	 AOS.init()
  }, []);


  return (
	<main>
	  <Seo
		title="Controle de Vendas e PDV 🖥"
		description="Sistema Web de frente de caixa e PDV (Ponto de Venda) completo e fácil de operar. Faça vendas de outras lojas (estoques) e emita notas fiscais em tempo real. Amplie sua frente de loja e venda mais! Teste grátis"
		keywords={[
			`Controle de vendas online`,
			`sistema pdv web`,
			`controle de multiplas lojas online`,
			`controle de locais de venda`,
			`multi empresas`,
			`estoques de outra loja`,
			`sistema fácil`,
			`amplie frente de loja`,
			`emita cupom fiscais`,
			`abrir e fechar caixa`,
		]}
	  />

	  <Header />
 
	  <section style={{paddingTop: `100px`}} className="overflow-x-hidden">

		<div className="max-w-6xl mx-auto">

			<div className="py-8 md:py-15">
			
			<div className="grid gap-3 sm:gap-8">
				
							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

									<StaticImage
										src="../images/controledevenda-topo-westoque.png"
										alt="Sistema wEstoque - PDV online, vendas e estoque integrados"
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={350}
										className="max-w-full mx-auto h-auto"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-12 md:pb-16 items-center text-center">
										<h1 className="text-center text-5xl font-bold" >
					  						Controle de Vendas e PDV
										</h1>
										<br/>
										<p className="text-xl text-gray-700 mb-4">Ganhe agilidade na hora de realizar pedidos! Com o wEstoque é possível lançar vendas através do código de barras, dar desconto por produto, fazer vendas por vendedor e trabalhar com múltiplos estoques e caixas. Todas as transações são integradas com o estoque e o financeiro, otimizando a gestão do negócio e franquias.</p>
										<br/>
										<form action="/usar">
										<div className="absolute w-full items-stretch mb-3 mx-auto text-center px-1 md:px-10">
										<div className="border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-yellow-500 transition-colors shadow-lg">
											<input type="email" id="txtEmail" name="txtEmail" className="w-full pl-3 pr-10 py-2 rounded-xl border-0" placeholder="Digite Seu E-mail"  />
											<button type="submit" className="block w-25 h-7 text-center font-extrabold text-sm leading-0 absolute top-2 right-2 focus:outline-none hover:text-gray-900 transition-colors text-green-500 px-1 md:px-10"><span role="img" aria-label="go emoji">✅</span>&nbsp; TESTAR AGORA! </button>  
										</div>
										</div>
									</form>
									</div>
								</div>
							</div>



							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-28">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

									<StaticImage
											src="../images/recursos-controle-de-multiplos-caixas.png"
											alt="Sistema wEstoque - PDV online, financeiro e fiscal"
											placeholder="blurred"
											quality={100}
											className="w-full"
										/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left align-top" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-semibold text-westoque-400"><span className="md:hidden" role="img" aria-label="caixas emoji">🗃</span> Controle de Múltiplos Caixas</h4>

											<div className="mt-1 text-md md:text-xl">
											O wEstoque controla um ou vários caixas simultaneamente. Gere recibos de todas as vendas ou escolha imprimir somente os recibos que você desejar.Escolha entre imprimir os recibos em folha A4 ou em impressora térmica (bobina).
											</div>
										</div>
									</div> 
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

										<StaticImage
											src="../images/recursos-emite-nfce.png"
											alt="Emita NF-e, NFC-e e controle toda operação online"
											placeholder="blurred"
											quality={100}
											className="w-full max-w-md"
										/>
										
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-400"><span className="md:hidden" role="img" aria-label="nfe emoji">🗒</span> Emissão de NFC-e & NF-e</h4>

											<div className="mt-1 text-md md:text-xl">
											Não se preocupe em acessar outros sistemas ou fazer operações díficeis para emitir e enviar a nota para o seu cliente, com o wEstoque você pode fazer a emissão após a conclusão da venda clicando em 1 botão só!
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">
									<StaticImage
										src="../images/recursos-multi-filiais.png"
										alt="Multi-filiais e várias empresas em um único sistema"
										placeholder="blurred"
										quality={100}
										className="w-full max-w-md"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left md:order-1" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-400"><span className="md:hidden" role="img" aria-label="house emoji">🏠</span> Multi Empresas & Estoques</h4>

											<div className="mt-1 text-md md:text-xl">
											Você que possui mais de uma filial ou franquia, precisa gerenciá-las. Com o wEstoque você pode controlar várias localidades e estoques tudo de um único lugar! Na correria do dia-a-dia é difícil separar todas as despesas, vendas, receitas e estoques identificadas por CNPJ. Deixe o nosso sistema de gerenciamento comercial fazer isso por você, nós centralizamos todas as informações do seu negócio.
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">
									<StaticImage
										src="../images/recursos-controle-de-vendedores-comissoes.png"
										alt="Controle de Vendedores e Comissões"
										placeholder="blurred"
										quality={100}
										className="w-full max-w-md"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-400"><span className="md:hidden" role="img" aria-label="vendedor emoji">💁🏻</span> Controle de Vendedores e Comissões</h4>

											<div className="mt-1 text-md md:text-xl">
											Controle as comissões de seus vendedores. Cadastre quantos funcionários forem necessários e configure a autonomia de cada vendedor. Você ainda pode definir no cadastro de cada usuário o desconto máximo que cada vendedor terá autonomia para aplicar, além de permitir ou não acesso aos dados e informações gerais do sistema. Você poderá acessar em tempo real relatório completo com a comissão de cada vendedor, quantidade de vendas, itens mais vendidos, receita bruta gerada e muito mais.
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 md:gap-6 items-center mt-36">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block" data-aos="fade-in">
				  					<StaticImage
										src="../images/call-pdv-roupas.png"
										alt="Controle sua loja com o sistema wEstoque"
										placeholder="blurred"
										layout="fullWidth"
										quality={100}
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-6 md:pb-8">
										<h1 className="text-center text-5xl font-bold mb-2 p-1" >
											<span className="underline decoration-westoque-400">Controle sua Loja</span> de forma <span className="underline decoration-westoque-400">Completa</span> e <br/><span className="underline decoration-sky-500">Segura</span>!
										</h1> 
										<div className="text-xl text-gray-700 mb-2 w-full">Com o <b className="text-2xl">w<span className="text-westoque-400">Estoque</span></b> você pode focar no seu negócio!</div>
									</div>
									<a className="max-w-xs font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-md text-white bg-westoque-400 hover:bg-westoque-500 hover:transition-transform hover:scale-110 shadow-md shadow-westoque-400/50 transition duration-150 ease-in-out" href="/usar ">EXPERIMENTE GRÁTIS</a>
								</div>
							</div>


						</div>
					</div>
				</div>
			</section>

	  <br/><br/>
	  <Footer />


	</main>
  )
}

export default RecursosPDVPage
